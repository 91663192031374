#kw-footer{
  min-height: 75px;
  background-color: $color-grey-3;
  .row-footer{
    font-size: $font-size-h6;
    margin: auto;
    padding-bottom: 20px;
    max-width: 1170px;
    //height: 75px;
    // position: relative;
  }
  .col-left{
    padding-top: 20px;
    // padding: 0px;
    float: left;
    text-align: left;
    font-size: $font-size-h5;
    letter-spacing: 0.5rem;
    @media screen and (max-width: 575px) {
      text-align: center;
      //display: none;
    }
  }
  .col-center{
    padding-top: 25px;
    // padding: 0px;
    float: right;
    text-align: center;
    @media screen and (max-width: 575px) {
      text-align: center;
      padding-top: 10px;
    }
  }
  .col-right{
    padding-top: 25px;
    // padding: 0px;
    float: right;
    text-align: right;
    @media screen and (max-width: 575px) {
      text-align: center;
      padding-top: 10px;
    }
  }
  a {
    cursor: auto;
    text-decoration: none;
    color: $color-grey-2;
    &:hover,&:active{
      color:$color-orange;
    }
  }
}