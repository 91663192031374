#kw-sliding .insta-sliding {
  position: relative;
  margin: 100px auto;
  max-width: 1200px;
  text-align: left;
  @media screen and (max-width: 1200px) {
    max-width: 900px;
  }
  @media screen and (max-width: 900px) {
    max-width: 600px;
  }
  @media screen and (max-width: 768px) {
    padding: 0px 15px;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    display: none;
    max-width: 300px;
  }
  .item{
    position: relative;
    margin: 0 10px 0px 10px ;
    display: inline-block;
    box-sizing: border-box;
    &:focus, &:active{
      outline: none!important;
      border: none!important;
    }
  }
  .item .item-feature-image{
    width: 285px;
    height: 285px;
    outline: none;
    border: none;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    padding: 0;
    //img{
    //    display: inline-block;
    //    height: 285px;
    //    width: auto;
    //  }
  }
}
.item .item-feature-image .item-content {
  opacity: 0;
  position: absolute;
  width: 285px;
  height: 285px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $color-orange;
  padding: 42px 20px;
  -webkit-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s;
  img{
    width: 100%;
    height: 100%;
  }
  &:hover{
    opacity: 80%;
  }
  .item-links{
    border: none;
    outline: none;
    text-align: center;
    padding-top: 35%;
    a:active, a:focus, a:hover, i:active, i:focus, i:hover, span:active, span:focus, span:hover {
      border: none;
      outline: none;
      text-decoration: none;
    }
    a,i,span{
      border: none;
      text-decoration: none;
      outline: none;
      color: #fff;
    }
  }
  .item-link-a, .item-link-b {
    position: relative;
    font-size: $font-size-h5;
    margin-left: 15px;
    margin-right: 15px;
    color: #fff;
  }
  .item-link-a:before {
    margin-right: 5px;
    font-size: $font-size-h3;
    font-family: "Font Awesome 5 Free"; content: "\f004";
    color: #fff;
    font-weight: bold;
  }
  .item-link-b {
    i{
      font-size: $font-size-h3;
      font-weight: bold;
      margin-right: 5px;
    }
  }
}
.slick-arrow{
  top:50%;
  width: 2.5rem;
  height: 2.5rem;
  //padding: 5px;
  &::before{
    color: #cecece;
  }
  &:hover,&:focus{
    &::before{
      color:  $color-orange;
      font-weight: bold;
    }
  }
}
.slick-prev{
 left: -50px;
  &:before{
    content: url('/assets/images/left-arrow.png');
  }
  &:hover:before{
    content: url('/assets/images/left-arrow-hover.png');
  }
  @media screen and (max-width: 768px) {
    left: -35px;
  }
}
.slick-next{
  right: -50px;
  &:before{
    content: url('/assets/images/right-arrow.png');
  }
  &:hover:before{
    content: url('/assets/images/right-arrow-hover.png');
  }
  @media screen and (max-width: 768px) {
    right: -35px;
  }
}
//.slick-prev::before{
//  //font-family: "AwesomePro"; font-weight: 300; font-size: 2.4rem; content: "\f137";
//  content: url('/assets/images/left-arrow.png');
//  &:hover{
//    content: url('/assets/images/left-arrow-hover.png');
//  }
//}
.slick-next::before{
  //font-family: "AwesomePro"; font-weight: 300; font-size: 2.4rem; content: "\f138";

}