/*
https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-weight
Common weight name mapping
The numerical values 100 to 900 roughly correspond to the following common weight names:
Value	Common weight name
100	Thin (Hairline)
200	Extra Light (Ultra Light)
300	Light
400	Normal
500	Medium
600	Semi Bold (Demi Bold)
700	Bold
800	Extra Bold (Ultra Bold)
900	Black (Heavy)

 */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Quicksand:wght@500;600;700&display=swap");
/*------------quicksand************/
/*
@font-face {
  font-family: 'Quicksand';
  src: url('/assets/fonts/Quicksand-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('/assets/fonts/Quicksand-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('/assets/fonts/Quicksand-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
*/
/*-------------poppins************/
/*
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
 */
/*-------------------************/
@font-face {
  font-family: 'AwesomePro';
  src: url("/assets/fonts/Font Awesome 5 Pro-Light-300.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'AwesomePro';
  src: url("/assets/fonts/Font Awesome 5 Pro-Regular-400.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AwesomePro';
  src: url("/assets/fonts/Font Awesome 5 Pro-Solid-900.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AwesomePro';
  src: url("/assets/fonts/Font Awesome 5 Pro-Regular-400.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AwesomePro';
  src: url("/assets/fonts/Font Awesome 5 Pro-Light-300.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

/*-------------------************/
@font-face {
  font-family: 'Hiragino Kaku Gothic ProN';
  src: url("/assets/fonts/hiragino-kaku-gothic-pron-w3.otf") format("opentype");
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

html,
body {
  overflow-x: hidden;
  height: 100%;
}

html {
  font-size: 62.5%;
}

/* =10px */
body {
  font-size: 1.6rem;
  font-family: Poppins, sans-serif;
  color: #30353a;
  line-height: 1.6;
  font-weight: normal;
}

.font-hiragino-kaku-gothic-pron-w3 {
  font-family: "Hiragino Kaku Gothic ProN";
}

.font-awesome-pro {
  font-family: AwesomePro;
}

.font-poppins {
  font-family: Poppins, sans-serif;
}

.font-quicksand {
  font-family: Quicksand, sans-serif;
}

.font-awesome-bold {
  font-weight: 900;
}

.font-bold {
  font-weight: 600;
}

.font-semi-bold {
  font-weight: 500;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-light {
  font-weight: 300;
}

h1 {
  font-size: 4.5rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.6rem;
}

h4 {
  font-size: 2.2rem;
}

h5 {
  font-size: 1.8rem;
}

h6 {
  font-size: 1.6rem;
}

h7 {
  font-size: 1.4rem;
}

h8 {
  font-size: 1.2rem;
}

.container {
  margin: auto;
  position: relative;
}

@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1600px) {
  .container {
    width: 1600px;
  }
}

h2.heading {
  cursor: default;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: #30353a;
  font-size: 4.0rem;
  line-height: 1.2;
  margin-bottom: 33px;
}

h2.heading + p {
  text-align: center;
  color: #30353a;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 300;
}

.btn {
  border-radius: 10px;
  padding: 13px 25px;
  font-size: 1.8rem;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none !important;
}

.btn:focus {
  box-shadow: none !important;
}

.btn-outline-secondary:hover, .btn-outline-secondary:active {
  border-color: #e95024 !important;
  background: #e95024 !important;
  color: #fff;
}

.btn-outline-secondary:focus {
  border-color: #ff8b52 !important;
  background: #ff8b52 !important;
}

.btn-primary {
  background-color: #ff8b52;
  border-color: #ff8b52;
  color: #fff;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  background-color: #e95024 !important;
  border-color: #e95024 !important;
  color: #fff;
}

.btn-primary:active:focus {
  background-color: #ff8b52 !important;
  border-color: #ff8b52 !important;
  color: #fff;
}

.form input {
  border: 1px solid #dde3ee;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  margin: 0 0 17px 0px;
  padding: 17px;
  box-sizing: border-box;
  font-size: 1.8rem;
  outline: none !important;
  color: #4f5864;
}

.form input::placeholder {
  color: #909cac;
}

.form button {
  margin-top: 25px;
}

.form button:hover, .form button:active, .form button:focus {
  background: #e95024;
}

.form .form_info {
  text-align: left;
  margin-top: -15px;
  margin-bottom: 15px;
}

.form .form_info a {
  text-decoration: none;
  color: #e95024;
}

.form .form_info span {
  font-size: 1.4rem;
}

.my-terms_accepted {
  position: relative;
  margin-top: 5px;
  font-size: 1.6rem;
}

.my-terms_accepted input {
  width: 1.8rem;
}

.my-terms_accepted a {
  color: #ff8b52;
  text-decoration: none;
}

.my-terms_accepted a:hover {
  color: #e95024;
}

input.form-check-input {
  margin: 0.5rem 0;
  font-size: 5rem;
  padding: 0;
  border-radius: 5px;
}

.hidden {
  display: none;
}

/******************** Custom checkbox ************************/
input[type="checkbox"], input[type="radio"] {
  position: absolute;
  outline: none;
  right: 9000px;
}

input[type="checkbox"] + .form-check-label {
  outline: none;
  border: none;
}

input[type="checkbox"] + .form-check-label:before {
  content: "\f0c8";
  font-family: "AwesomePro";
  font-size: 1.8rem;
  border-radius: 5px;
  color: #dde3ee;
  speak: none;
  font-style: normal;
  font-weight: 300;
  text-transform: none;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  width: 1.8rem;
  display: inline-block;
  margin-right: 5px;
  outline: none;
  border: none;
  transform: scale(1.1);
}

input[type="checkbox"]:checked + .form-check-label:before {
  content: "\f14a";
  color: #ff8b52;
  font-weight: bold;
  animation: effect 250ms ease-in;
  outline: none;
  border: none;
}

input[type="checkbox"]:disabled + .form-check-label {
  color: #fff;
}

input[type="checkbox"]:disabled + .form-check-label:before {
  content: "\f0c8";
  color: #fff;
}

/*Radio box*/
input[type="radio"] + .form-check-label {
  outline: none;
}

input[type="radio"] + .form-check-label:before {
  content: "\f111";
  font-family: "AwesomePro";
  font-size: 1.8rem;
  border-radius: 5px;
  color: #dde3ee;
  speak: none;
  font-style: normal;
  font-weight: 300;
  text-transform: none;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  width: 1.8rem;
  display: inline-block;
  margin-right: 5px;
  outline: none;
  border: none;
  transform: scale(1.1);
}

input[type="radio"]:checked + .form-check-label:before {
  content: "\f192";
  color: #ff8b52;
  font-weight: bold;
  animation: effect 250ms ease-in;
  outline: none;
  border: none;
}

input[type="radio"]:disabled + .form-check-label {
  color: #ccc;
}

input[type="radio"]:disabled + .form-check-label:before {
  content: "\f192";
  color: #ccc;
}

/********************animation ************************/
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes effect {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

/****************************/
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #fff;
}

.hamburger-box {
  width: 25px;
  height: 17px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 25px;
  height: 3px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -7px;
}

.hamburger-inner::after {
  bottom: -7px;
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -14px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

#top-nav {
  margin-bottom: 0;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  border-radius: 0;
  border: none;
  z-index: 20;
  min-height: 80px;
  background: #ffffff;
  box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.24);
}

@media screen and (max-width: 767px) {
  #top-nav {
    min-height: 60px;
  }
}

#top-nav .container {
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0;
}

@media screen and (max-width: 767px) {
  #top-nav .container {
    max-width: 767px;
  }
}

#top-nav .nav-content {
  position: relative;
  margin: auto;
  text-align: right;
  max-width: 1170px;
}

#top-nav .navbar-header {
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 80px;
  text-align: left;
}

#top-nav .navbar-header label.close-menu {
  display: block;
  position: absolute;
  margin-top: 40px;
  left: 11px;
  padding: 4px;
}

@media screen and (max-width: 767px) {
  #top-nav .navbar-header label.close-menu {
    margin-top: 32px;
  }
}

#top-nav .navbar-header .navbar-logo, #top-nav .navbar-header .navbar-logo-ex {
  padding-left: 10px;
}

#top-nav .navbar-header img {
  margin-top: -30px;
  height: 140px;
  transition: 0.2s;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  #top-nav .navbar-header {
    text-align: center;
  }
  #top-nav .navbar-header img {
    position: absolute;
    margin-top: -20px;
    height: 100px;
  }
  #top-nav .navbar-header .navbar-logo {
    padding-right: 125px;
  }
  #top-nav .navbar-header .navbar-logo-ex {
    padding-right: 150px;
  }
}

#top-nav .navbar-right, .navbar-right-sign-in {
  z-index: 1;
  position: relative;
  transition: 0.5s;
}

#top-nav .navbar-right ul.primary_menu, .navbar-right-sign-in ul.primary_menu {
  padding-top: 2.2rem;
  justify-content: flex-end !important;
}

#top-nav .navbar-right ul.primary_menu li, .navbar-right-sign-in ul.primary_menu li {
  padding-left: 1.6rem;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  #top-nav .navbar-right ul.primary_menu li, .navbar-right-sign-in ul.primary_menu li {
    padding-left: 20px;
  }
}

#top-nav .navbar-right ul.primary_menu li > a, .navbar-right-sign-in ul.primary_menu li > a {
  font-family: Poppins, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
}

#top-nav .navbar-right ul.primary_menu li > a.nav-link, .navbar-right-sign-in ul.primary_menu li > a.nav-link {
  color: #4f5864;
}

#top-nav .navbar-right ul.primary_menu li > a.nav-link:hover, .navbar-right-sign-in ul.primary_menu li > a.nav-link:hover {
  color: #ff8b52;
}

#top-nav .navbar-right ul.primary_menu li > a.nav-link.active, .navbar-right-sign-in ul.primary_menu li > a.nav-link.active {
  color: #ff8b52;
}

#top-nav .navbar-right ul.primary_menu li .div-center, .navbar-right-sign-in ul.primary_menu li .div-center {
  margin: auto;
}

#top-nav .navbar-right ul.primary_menu .vertical-divider, .navbar-right-sign-in ul.primary_menu .vertical-divider {
  border-left: 1px solid #dde3ee;
  display: block;
  padding: 0 0;
  height: 70%;
  margin: 5px 0;
  left: 50%;
}

@media screen and (max-width: 767px) {
  #top-nav .navbar-right ul.primary_menu, .navbar-right-sign-in ul.primary_menu {
    padding-top: 1.2rem;
  }
}

#top-nav .navbar-right {
  margin-left: 200px;
}

@media screen and (max-width: 767px) {
  #top-nav .navbar-right ul.primary_menu {
    justify-content: left !important;
    padding-top: 55px;
    padding-bottom: 2000px;
    width: 100%;
    display: block;
    text-align: left;
    clear: left;
  }
  #top-nav .navbar-right ul.primary_menu li {
    padding-bottom: 10px;
  }
  #top-nav .navbar-right ul.primary_menu .sign-in {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #top-nav .navbar-right {
    overflow: hidden;
    max-height: 1px;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    width: 100%;
    margin-left: 0;
    background-color: #fff;
    z-index: -1;
    padding-top: 0;
    padding-bottom: 0;
    border-top-width: 1px;
    border-top-style: solid;
    border-color: #e6f1f5;
  }
}

#top-nav .navbar-right-sign-in {
  width: 25%;
  margin-left: 75%;
}

#top-nav .navbar-right-sign-in li {
  padding-right: 5px;
}

@media screen and (max-width: 767px) {
  #top-nav .navbar-right-sign-in {
    width: 35%;
    margin-left: 65%;
  }
}

#my-hamburger {
  margin-top: 2.0rem;
  float: right;
}

#my-hamburger .hamburger-box {
  height: 25px;
}

#my-hamburger .hamburger-inner, #my-hamburger .hamburger-inner::before, #my-hamburger .hamburger-inner::after {
  background-color: #4f5864;
  bottom: auto;
}

#my-hamburger .hamburger-inner:hover, #my-hamburger .hamburger-inner::before:hover, #my-hamburger .hamburger-inner::after:hover {
  background-color: #ff8b52 !important;
}

@media screen and (min-width: 768px) {
  #my-hamburger {
    display: none !important;
  }
}

@media screen and (min-width: 767px) {
  #top-nav .navbar-right-sign-in {
    display: none;
  }
}

/***********My Account Menu ***********/
#top-nav .nav-item.sign-in {
  position: relative;
}

#top-nav .avatar-img {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: auto 100%;
}

#top-nav .nav-link.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: 0.2rem;
  font-family: "AwesomePro";
  content: "\f107";
  font-size: 1.8rem;
  font-weight: 300;
  color: #909cac;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}

#top-nav .nav-link:focus {
  outline: none;
}

#top-nav .my-account-menu.dropdown-menu a {
  display: block;
  color: #4f5864;
  text-decoration: none;
  border: none;
  outline: 0;
  font-size: 1.6rem !important;
}

#top-nav .my-account-menu.dropdown-menu a:hover, #top-nav .my-account-menu.dropdown-menu a:active, #top-nav .my-account-menu.dropdown-menu a:focus {
  color: #ff8b52;
}

#top-nav .my-account-menu.dropdown-menu li {
  padding-left: 0 !important;
  position: relative;
  border-left: 0px solid transparent;
}

#top-nav .my-account-menu.dropdown-menu li:hover:before {
  position: absolute;
  border-left: 3px solid #ff8b52;
  content: "x";
  top: 0.7rem;
  font-size: 1.2rem;
  color: transparent;
  background-color: transparent;
}

#top-nav .my-account-menu.dropdown-menu li a {
  padding: 5px 25px;
  font-weight: 400 !important;
  font-size: 1.4rem !important;
}

#top-nav .my-account-menu.dropdown-menu {
  position: relative;
  float: none;
  margin: 30px 0 20px 0;
  border-radius: 5px;
  padding: 20px 0;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.1);
}

#top-nav .my-account-menu.dropdown-menu a:hover,
#top-nav .my-account-menu.dropdown-menu a:active,
#top-nav .my-account-menu.dropdown-menu a:focus {
  background-color: transparent;
}

#top-nav .my-account-menu.dropdown-menu {
  left: auto !important;
  right: 20px !important;
  border: none;
  top: 15px;
}

#kw-footer {
  min-height: 75px;
  background-color: #e6f1f5;
}

#kw-footer .row-footer {
  font-size: 1.6rem;
  margin: auto;
  padding-bottom: 20px;
  max-width: 1170px;
}

#kw-footer .col-left {
  padding-top: 20px;
  float: left;
  text-align: left;
  font-size: 1.8rem;
  letter-spacing: 0.5rem;
}

@media screen and (max-width: 575px) {
  #kw-footer .col-left {
    text-align: center;
  }
}

#kw-footer .col-center {
  padding-top: 25px;
  float: right;
  text-align: center;
}

@media screen and (max-width: 575px) {
  #kw-footer .col-center {
    text-align: center;
    padding-top: 10px;
  }
}

#kw-footer .col-right {
  padding-top: 25px;
  float: right;
  text-align: right;
}

@media screen and (max-width: 575px) {
  #kw-footer .col-right {
    text-align: center;
    padding-top: 10px;
  }
}

#kw-footer a {
  cursor: auto;
  text-decoration: none;
  color: #4f5864;
}

#kw-footer a:hover, #kw-footer a:active {
  color: #ff8b52;
}

/****************************/
#kw-header {
  background-color: #e6f1f5;
}

#kw-header .row-header {
  margin: auto;
  max-width: 1170px;
  height: 778px;
  position: relative;
}

@media screen and (max-width: 768px) {
  #kw-header .row-header {
    height: 550px;
  }
}

@media screen and (max-width: 768px) {
  #kw-header .row-header {
    height: 720px;
  }
}

#kw-header .header-wrap {
  position: relative;
  margin: 80px auto 0 auto;
  max-width: 1400px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #kw-header .header-wrap {
    margin: 40px auto 0 auto;
  }
}

#kw-header .col-left {
  transition: 0.1s;
  display: inline-block;
  padding-top: 108px;
  padding-left: 0px;
  height: 100%;
  float: left;
}

@media screen and (max-width: 960px) {
  #kw-header .col-left .my-form-group {
    text-align: center;
  }
}

#kw-header .col-left .header-title {
  color: #ff8b52;
  text-transform: uppercase;
  font-size: 1.8rem;
}

#kw-header .col-left .header-desc {
  display: table;
  padding-top: 8px;
  padding-bottom: 15px;
  font-size: 4.5rem;
  color: #30353a;
  background-image: url("/assets/images/doodle.png");
  background-repeat: no-repeat;
  background-position: right bottom;
}

@media screen and (max-width: 960px) {
  #kw-header .col-left .header-desc {
    background-image: none;
  }
}

#kw-header .col-left .text-content {
  width: 100%;
  max-width: 510px;
  font-size: 1.8rem;
  color: #30353a;
  padding-bottom: 58px;
}

@media screen and (max-width: 768px) {
  #kw-header .col-left .text-content {
    padding-top: 60px;
    margin: auto;
  }
}

#kw-header .col-left .form .my-form-group {
  position: relative;
  width: 100%;
  display: table;
}

#kw-header .col-left .form .my-form-group .my-form-search {
  margin: 0;
}

#kw-header .col-left .form .my-form-group button.btn {
  color: #fff;
  background-color: #ff8b52;
  border: none;
  padding: 0;
  margin: -3px 0 0 -58px;
  width: 50px;
  height: 50px;
  text-align: center;
  background-image: url("/assets/images/search-icon.png");
  background-repeat: no-repeat;
  background-position: center;
}

#kw-header .col-left .form .my-form-group button.btn:hover {
  background-color: #e95024;
}

#kw-header .col-left .form .my-form-group button.btn:active:focus {
  background-color: #ff8b52;
}

#kw-header .header-animate {
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0;
  width: 1400px;
  height: 100%;
  left: 50%;
  transform: translate(-50%, 0);
}

#kw-header .header-animate .item-obj {
  position: absolute;
  display: block;
}

#kw-header .header-animate .character {
  top: 48px;
  left: 530px;
}

#kw-header .header-animate .character img {
  height: 730px;
}

@media screen and (max-width: 768px) {
  #kw-header .header-animate .character {
    display: none;
  }
}

#kw-header .header-animate .dot1 {
  position: absolute;
  width: 70px;
  height: 70px;
  left: 100px;
  bottom: 45px;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header/dot1.png");
}

@media screen and (max-width: 1200px) {
  #kw-header .header-animate .dot1 {
    display: none;
  }
}

#kw-header .header-animate .dot2 {
  width: 116px;
  height: 70px;
  left: 1250px;
  bottom: 25px;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header/dot2.png");
}

@media screen and (min-width: 1400px) {
  #kw-header .header-animate .dot2 {
    right: 70px;
  }
}

#kw-header .header-animate .dot3 {
  width: 70px;
  height: 70px;
  left: 520px;
  bottom: 120px;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header/dot3.png");
}

#kw-header .header-animate .dot4 {
  width: 70px;
  height: 180px;
  left: 50%;
  top: 160px;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header/dot4.png");
}

@media screen and (max-width: 1200px) {
  #kw-header .header-animate .dot4 {
    display: none;
  }
}

#kw-header .header-animate .dot5 {
  width: 160px;
  height: 30px;
  left: 40%;
  bottom: 0px;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header/dot5.png");
}

#kw-header .header-animate .dot6 {
  width: 20px;
  height: 160px;
  left: 1250px;
  bottom: 450px;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header/dot6.png");
}

#kw-sliding .insta-sliding {
  position: relative;
  margin: 100px auto;
  max-width: 1200px;
  text-align: left;
}

@media screen and (max-width: 1200px) {
  #kw-sliding .insta-sliding {
    max-width: 900px;
  }
}

@media screen and (max-width: 900px) {
  #kw-sliding .insta-sliding {
    max-width: 600px;
  }
}

@media screen and (max-width: 768px) {
  #kw-sliding .insta-sliding {
    padding: 0px 15px;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  #kw-sliding .insta-sliding {
    display: none;
    max-width: 300px;
  }
}

#kw-sliding .insta-sliding .item {
  position: relative;
  margin: 0 10px 0px 10px;
  display: inline-block;
  box-sizing: border-box;
}

#kw-sliding .insta-sliding .item:focus, #kw-sliding .insta-sliding .item:active {
  outline: none !important;
  border: none !important;
}

#kw-sliding .insta-sliding .item .item-feature-image {
  width: 285px;
  height: 285px;
  outline: none;
  border: none;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  padding: 0;
}

.item .item-feature-image .item-content {
  opacity: 0;
  position: absolute;
  width: 285px;
  height: 285px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ff8b52;
  padding: 42px 20px;
  -webkit-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s;
}

.item .item-feature-image .item-content img {
  width: 100%;
  height: 100%;
}

.item .item-feature-image .item-content:hover {
  opacity: 80%;
}

.item .item-feature-image .item-content .item-links {
  border: none;
  outline: none;
  text-align: center;
  padding-top: 35%;
}

.item .item-feature-image .item-content .item-links a:active, .item .item-feature-image .item-content .item-links a:focus, .item .item-feature-image .item-content .item-links a:hover, .item .item-feature-image .item-content .item-links i:active, .item .item-feature-image .item-content .item-links i:focus, .item .item-feature-image .item-content .item-links i:hover, .item .item-feature-image .item-content .item-links span:active, .item .item-feature-image .item-content .item-links span:focus, .item .item-feature-image .item-content .item-links span:hover {
  border: none;
  outline: none;
  text-decoration: none;
}

.item .item-feature-image .item-content .item-links a, .item .item-feature-image .item-content .item-links i, .item .item-feature-image .item-content .item-links span {
  border: none;
  text-decoration: none;
  outline: none;
  color: #fff;
}

.item .item-feature-image .item-content .item-link-a, .item .item-feature-image .item-content .item-link-b {
  position: relative;
  font-size: 1.8rem;
  margin-left: 15px;
  margin-right: 15px;
  color: #fff;
}

.item .item-feature-image .item-content .item-link-a:before {
  margin-right: 5px;
  font-size: 2.6rem;
  font-family: "Font Awesome 5 Free";
  content: "\f004";
  color: #fff;
  font-weight: bold;
}

.item .item-feature-image .item-content .item-link-b i {
  font-size: 2.6rem;
  font-weight: bold;
  margin-right: 5px;
}

.slick-arrow {
  top: 50%;
  width: 2.5rem;
  height: 2.5rem;
}

.slick-arrow::before {
  color: #cecece;
}

.slick-arrow:hover::before, .slick-arrow:focus::before {
  color: #ff8b52;
  font-weight: bold;
}

.slick-prev {
  left: -50px;
}

.slick-prev:before {
  content: url("/assets/images/left-arrow.png");
}

.slick-prev:hover:before {
  content: url("/assets/images/left-arrow-hover.png");
}

@media screen and (max-width: 768px) {
  .slick-prev {
    left: -35px;
  }
}

.slick-next {
  right: -50px;
}

.slick-next:before {
  content: url("/assets/images/right-arrow.png");
}

.slick-next:hover:before {
  content: url("/assets/images/right-arrow-hover.png");
}

@media screen and (max-width: 768px) {
  .slick-next {
    right: -35px;
  }
}
