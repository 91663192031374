#top-nav{
  margin-bottom: 0;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  border-radius: 0;
  border: none;
  z-index: 20;
  min-height: 80px;
  background: #ffffff;
  box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.24);
  @media screen and (max-width: 767px) {
    min-height: 60px;
  }
}
#top-nav .container{
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0;
  @media screen and (max-width: 767px) {
    max-width: 767px;
  }
}
#top-nav .nav-content{
  position: relative;
  margin: auto;
  text-align: right;
  max-width: 1170px;
}

#top-nav .navbar-header{
  z-index: 0;
  //position: fixed;
  //left: 0;
  position: absolute;
  width: 100%;
  height: 80px;
  text-align: left;
  //background-color: #abb4ae;
  label.close-menu{
    display: block;
    position: absolute;
    margin-top: 40px;
    left: 11px;
    //top: 11px;
    padding: 4px;
    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }
  .navbar-logo,.navbar-logo-ex{
    padding-left:10px;
  }
  img {
    //margin-left:80px;
    margin-top: -30px;
    height: 140px;
    //width: 140px;
    transition: 0.2s;
    vertical-align: middle;
  }
  @media screen and (max-width: 767px) {

    text-align: center;
    img {
      position: absolute;
      margin-top: -20px;
      height: 100px;
    }
    .navbar-logo{
      padding-right: 125px;
    }
    .navbar-logo-ex{
      padding-right: 150px;
    }
  }
}
// right menu
#top-nav .navbar-right, .navbar-right-sign-in {
  z-index: 1;
  position: relative;
  transition: 0.5s;

  ul.primary_menu {
    padding-top: 2.2rem;
    justify-content: flex-end !important;
    li {
      padding-left: 1.6rem;
      vertical-align: middle;
      @media screen and (max-width: 768px) {
        padding-left: 20px;
      }
    }

    li > a {
      font-family: $font-poppins;
      font-size: $font-size-base;
      font-weight: $font-weight-medium;
    }

    li > a.nav-link {
      color: $color-grey-2;
    }

    li > a.nav-link:hover {
      color: $color-orange;
    }

    li > a.active {
      //font-size: 1.6rem;
      //font-weight: 500;
    }

    li > a.nav-link.active {
      color: $color-orange;
    }

    li .div-center {
      margin: auto;
    }

    .vertical-divider {
      border-left: 1px solid $color-grey-1;
      display: block;
      padding: 0 0;
      height: 70%;
      margin: 5px 0;
      //position: absolute;
      left: 50%;
      //top: 0;
    }
    @media screen and (max-width: 767px) {
      padding-top: 1.2rem;
    }
  }
}
#top-nav .navbar-right{
  //width: 100%;
  margin-left: 200px;
  ul.primary_menu {
    @media screen and (max-width: 767px) {
      justify-content: left !important;
      //max-height: 420px;
      padding-top: 55px;
      padding-bottom: 2000px;
      //overflow: hidden;
      width: 100%;
      display: block;
      text-align: left;
      clear: left;
      li{
        padding-bottom: 10px;
      }
      .sign-in {
        display: none;
      }
    }
  }
  //@media screen and (max-width: 991px) {
  //  display: block;
  //  text-align: center;
  //  position: relative;
  //  float: none;
  //  width: 100%;
  //}
  @media screen and (max-width: 767px) {
    overflow: hidden;
    max-height: 1px;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    width: 100%;
    margin-left: 0;
    background-color: #fff;
    z-index: -1;
    padding-top: 0;
    padding-bottom: 0;
    border-top-width: 1px;
    border-top-style: solid;
    border-color: $color-grey-3;
  }
}
#top-nav .navbar-right-sign-in {
  width: 25%;
  margin-left: 75%;
  li{
    padding-right: 5px;
  }
  @media screen and (max-width: 767px){
    width: 35%;
    margin-left: 65%;
  }
}
//hambuger
//TOTO
#my-hamburger {
  //z-index: 2;
  margin-top: 2.0rem;
  float: right;
  //margin-right: 5px;
  .hamburger-box{
    height: 25px;
  }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after
  {
    //z-index: 3;
    background-color: $color-grey-2;
    bottom: auto;
    &:hover{
      background-color: $color-orange!important;
    }
  }
  @media screen and (max-width: 768px) {

  }
  //.hamburger-inner:hover, .hamburger-inner:hover::before, .hamburger-inner:hover::after
  //{
  //  background-color: $color-orange!important;
  //}
  //.hamburger-inner:active, .hamburger-inner:before:active, .hamburger-inner:after:active
  //{
  //  background-color: $color-orange!important;
  //}
}
@media screen and (min-width: 768px) {
  #my-hamburger {
    display: none!important;
  }
}
#top-nav .navbar-right-sign-in{
  @media screen and (min-width: 767px) {
    display: none;
  }
}
/***********My Account Menu ***********/
#top-nav .nav-item.sign-in{
  position: relative;
}

#top-nav .avatar-img{
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  //padding-top: 0px;
  border-radius: 50% ;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: auto 100%;
}

#top-nav .nav-link.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.5rem;//.255em;
  vertical-align: 0.2rem;//.255em;
  font-family: "AwesomePro"; content: "\f107";
  font-size: 1.8rem;
  font-weight: $font-weight-light;
  color: $color-grey;
  border-top: 0;//.3em solid;
  border-right: 0;//.3em solid transparent;
  border-bottom: 0;
  border-left: 0;//.3em solid transparent;
}

#top-nav .nav-link:focus{
  outline: none;
}
#top-nav .my-account-menu.dropdown-menu a{
  display: block;
  color: $color-grey-2;
  text-decoration: none;
  border: none;
  outline:0;
  font-size: $font-size-h6 !important;
  &:hover,&:active,&:focus{
    color: $color-orange;
  }
}
#top-nav .my-account-menu.dropdown-menu li{
  padding-left: 0!important;
  position: relative;
  border-left: 0px solid transparent;
  &:hover:before{
    position: absolute;
    border-left: 3px solid $color-orange;
    content: "x";
    top:0.7rem;
    font-size: 1.2rem;
    color: transparent;
    background-color: transparent;
  }
  a{
    padding: 5px 25px;
    font-weight: $font-weight-normal!important;
    font-size: $font-size-h7!important;
  }
}
#top-nav .my-account-menu.dropdown-menu {
  position: relative;
  float: none;
  //min-width: 180px;
  //width: 100%;
  //padding: 0 5px;
  margin: 30px 0 20px 0;
  border-radius: 5px;
  //border: none;
  //box-shadow: none;
  padding: 20px 0;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.1);
}

#top-nav .my-account-menu.dropdown-menu a:hover,
#top-nav .my-account-menu.dropdown-menu a:active,
#top-nav .my-account-menu.dropdown-menu a:focus,
{
  //color: $default-hover-color;
  background-color: transparent;
}
#top-nav .my-account-menu.dropdown-menu{
  left:auto!important;
  right: 20px!important;
  border: none;
  top: 15px;
}
//#top-nav .my-account-menu.dropdown-menu.show {
//  border: none;
//  top: 15px;
//
//}