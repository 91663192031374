#kw-header{
  background-color: $color-grey-3;
  .row-header{
    margin: auto;
    max-width: 1170px;
    height: 778px;
    position: relative;
    @media screen and (max-width: 768px) {
      height: 550px;
    }
    @media screen and (max-width: 768px) {
      height: 720px;
    }
  }
}
#kw-header .header-wrap {
  position: relative;
  margin: 80px auto 0 auto;
  max-width: 1400px;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin: 40px auto 0 auto;
  }
}
#kw-header .col-left{
  transition: 0.1s;
  display: inline-block;
  padding-top: 108px;
  padding-left: 0px;
  // max-width: 50%;
  height: 100%;
  float: left;
  @media screen and (max-width: 960px) {
    //padding-top: 40px;
    //text-align: center;
    .my-form-group {
      text-align: center;
    }
  }
  .header-title{
    color: $color-orange;
    text-transform: uppercase;
    font-size: $font-size-h5;
  }
  .header-desc{
    display: table;
    padding-top: 8px;
    padding-bottom: 15px;
    font-size: $font-size-h1;
    color: $color-black;
    background-image: url('/assets/images/doodle.png');
    background-repeat: no-repeat;
    background-position: right bottom ;
    @media screen and (max-width: 960px) {
      background-image: none;
    }
  }
  .text-content{
    width: 100%;
    max-width: 510px;
    font-size: $font-size-h5;
    color: $color-black;
    padding-bottom: 58px;
    @media screen and (max-width: 768px) {
      padding-top: 60px;
      margin: auto;
    }
  }
  .form {
    @media screen and (max-width: 600px) {

    }
  }
  .form .my-form-group {
    position: relative;
    width: 100%;
    display: table;
    .my-form-search {
      margin: 0;
    }
    button.btn{
      color: #fff;
      background-color: $color-orange;
      border: none;
      padding: 0;
      margin: -3px 0 0 -58px;
      width: 50px;
      height: 50px;
      text-align: center;
      &:hover{
        background-color: $color-orange-2;
      }
      &:active:focus {
        background-color: $color-orange;
      }
      background-image: url('/assets/images/search-icon.png');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

//#kw-header .btn.btn-info {
//  background-color: $button-normal-color;
//  border-color: $button-normal-color;
//}
//#kw-header .btn {
//  padding: 2px 10px;
//  border-radius: 10px;
//  width: auto;
//  margin-left: -46px;
//  margin-top: 5px;
//  font-size: 2.2rem;
//  opacity: 1!important;
//  height: 40px;
//  text-align: center;
//  font-weight: bold;
//  color: #fff;
//  text-transform: uppercase;
//  border: none;
//  letter-spacing: 0px;
//  transition: background-color .3s;
//  @media screen and (max-width: 767px) {
//    margin-left: -53px;
//  }
//}
#kw-header .header-animate{
  margin-left: auto;
  margin-right: auto;
  //background-color: #ccc;
  position: absolute;
  top: 0;
  width: 1400px;
  height: 100%;
  left: 50%;
  transform: translate(-50%, 0);
}
#kw-header .header-animate .item-obj{
  position: absolute;
  display: block;
  //top: 0;
  //left: 0;
  //right: 0;
  //bottom: 300px;
}
#kw-header .header-animate{
  .character {
    top: 48px;
    left: 530px;
    //width: 174px;
    //height: 86.33px;
    img {
      //width: 174px;
      height: 730px;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .dot1{
    position: absolute;
    width: 70px;
    height:70px;
    left: 100px;
    bottom: 45px;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/header/dot1.png");
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  .dot2{
    width: 116px;
    height:70px;
    // margin-right: -20px;
    left:1250px;
    bottom: 25px;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/header/dot2.png");
    @media screen and (min-width: 1400px) {
      right: 70px;
    }
  }
  .dot3{
    width: 70px;
    height:70px;
    left: 520px;
    bottom: 120px;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/header/dot3.png");
  }
  .dot4{
    width: 70px;
    height: 180px;
    left: 50%;
    top: 160px;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/header/dot4.png");
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  .dot5{
    width: 160px;
    height:30px;
    left: 40%;
    bottom: 0px;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/header/dot5.png");
  }
  .dot6{
    width: 20px;
    height: 160px;
    left:  1250px;
    bottom: 450px;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/header/dot6.png");
  }
}