@import "./variables";
@import "./mixins";
@import "./fonts";
@import "./common";
/****************************/
@import "./components";
@import "./partials/navbar";
@import "./partials/footer";
/****************************/
@import "./partials/header";
@import "./partials/sliding";